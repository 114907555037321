// @flow

import React from "react";
import Helmet from "react-helmet";
import styled from "@emotion/styled";

import useSiteMetadata from "../hooks/use-sitemetadata";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const LayoutStyled = styled("div")`
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  line-height: 1.4;
  min-height: 100vh;

  *:focus {
    box-shadow: none !important;
    outline: 2px dashed #00000099;
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 960px;
    }
  }
`;

const ContentStyled = styled("div")`
  // margin-top: 300px;

  a {
    color: black;
  }
  p,
  ul,
  ol {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
    white-space: pre-wrap;
  }
  h1 {
    font-size: 24px;
    line-height: 33px;
    font-weight: bold;
    border-bottom: 2px solid black;
    padding-bottom: 10px;
    margin-bottom: 1.5em;
  }
  h2 {
    margin-top: 1.5em;
    font-size: 22px;
    font-weight: bold;
  }
  h3,
  h4 {
    font-size: 20px;
    font-weight: bold;
  }
`;

type Props = {
  children: Node,
  pageContext: Object,
  title: string
};

function Layout(props: Props) {
  let { title = "" } = props;
  const { children, pageContext = {} } = props;
  const metadata = useSiteMetadata();

  if (pageContext.frontmatter && pageContext.frontmatter.title) {
    title = pageContext.frontmatter.title;
  }

  return (
    <>
      <Helmet>
        <html lang="pl" />
        <title>{[title, metadata.title].filter(Boolean).join(" – ")}</title>
        <meta name="description" content={metadata.description} />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <LayoutStyled className="d-flex flex-column">
        <Navbar />
        <ContentStyled className="p-3 p-sm-0 flex-grow-1 content container">
          {children}
        </ContentStyled>
        <Footer />
      </LayoutStyled>
    </>
  );
}

export default Layout;

// @flow

import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";

const LinkStyled = styled(Link)`
  font-size: 20px;
  color: black;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover {
    color: black;
  }

  &.current-page {
    background: white;
  }
`;

function Menu() {
  return (
    <nav className="d-flex flex-column flex-md-row justify-content-between mx-n3 mx-md-0">
      <LinkStyled
        className="px-4 ml-md-n4"
        activeClassName="current-page"
        to="/zespoly/"
      >
        Zespoły
      </LinkStyled>
      <LinkStyled
        className="px-4"
        activeClassName="current-page"
        to="/edukacja/"
      >
        Edukacja
      </LinkStyled>
      <LinkStyled
        className="px-4"
        activeClassName="current-page"
        to="/o-projekcie/"
      >
        O projekcie
      </LinkStyled>
      <LinkStyled
        className="px-4 mr-md-n4"
        activeClassName="current-page"
        to="/kontakt/"
      >
        Kontakt
      </LinkStyled>
    </nav>
  );
}

export default Menu;

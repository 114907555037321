// @flow

import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";

const FooterStyled = styled("footer")`
  background: black;

  ul {
    list-style-type: none;
  }
`;

const LinkStyled = styled(Link)`
  font-size: 18px;
  font-weight: bold;
  color: white;

  &:hover {
    color: white;
  }
`;

function Footer() {
  return (
    <FooterStyled className="mt-5">
      <div className="py-4 container">
        <div className="row">
          <div className="d-flex col-12 px-3 px-sm-0">
            <ul className="d-flex flex-column flex-sm-row p-0 m-0 ml-sm-auto">
              <li className="ml-0 ml-sm-4 ml-md-5">
                <LinkStyled to="/mapa-strony/">Mapa strony</LinkStyled>
              </li>
              <li className="ml-0 ml-sm-4 ml-md-5">
                <LinkStyled to="/deklaracja-dostepnosci/">
                  Deklaracja dostępności
                </LinkStyled>
              </li>
              <li className="ml-0 ml-sm-4 ml-md-5">
                <LinkStyled to="/polityka-prywatnosci/">
                  Polityka prywatności
                </LinkStyled>
              </li>
              <li className="ml-0 ml-sm-4 ml-md-5">
                <LinkStyled to="/licencje/">Licencje</LinkStyled>
              </li>
              <li className="ml-0 ml-sm-4 ml-md-5">
                <LinkStyled to="/kontakt/">Kontakt</LinkStyled>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </FooterStyled>
  );
}

export default Footer;

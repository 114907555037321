// @flow

import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";

import Logo from "../assets/logo.svg";
import Menu from "./Menu";

// $FlowFixMe
const NavbarStyled = styled("header")`
  background: #fddb5d;
  margin-bottom: 85px;

  @media (max-width: 769px) {
    margin-bottom: 50px;
  }
`;

const LogoStyled = styled(Logo)`
  width: 170px;
`;

function Navbar() {
  return (
    <NavbarStyled>
      <div className="d-flex flex-column justify-content-end p-0 container">
        <div className="d-flex justify-content-between mx-3 mx-sm-0 my-5">
          <Link to="/zespoly/">
            <LogoStyled />
          </Link>
        </div>
        <Menu />
      </div>
    </NavbarStyled>
  );
}

export default Navbar;
